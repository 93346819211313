.login {
    background-color: #004984;
    height: 100vh;
}

.login .login-box {
    background-color: #fff;
    min-width: 35vw;
    min-height: 30vh;
    background: aliceblue;
    border-radius: 10px;
    padding: 15px;
}

.img-login {
    width: 150px;
}

.modal-login {
    border: none !important;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    position: relative;
}

.modal-login > .cancel {
    width: 25px;
    right: 5%;
    top: 8%;
    position: absolute;
    cursor: pointer;
}

.modal-login > .check {
    width: 50px;
    margin-top: 25px;
}

.modal-company {
    border: none !important;
    border-radius: 10px;
    padding: 50px;
    justify-content: center;
    flex-direction: column;
    display: flex;
    position: relative;
}

.link-password-reset {
    color: #ee7330 !important;
    font-weight: 600;
    margin-top: 10px;
}
